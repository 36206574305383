<template>
  <div class="five-order-main titlesTop">
    <headTitle v-if="!show" title="五单派送" />
    <div v-if="show">
      <div class="tree-head">
        <span class="first-back iconfont icon-fanhui" @click="closeTree"></span>
      </div>
      <tree :list="serveList" :treetype="2"></tree>
    </div>
    <div v-else>
      <div class="btn-item">
        <img class="btn-pic" src="@/assets/images/kaimenxd.png" alt="" @click="goToPage('askOrder')">
        <img class="btn-arrow" src="../../assets/images/ddjiantou.png" alt="">
      </div>
      <div class="btn-item">
        <img class="btn-pic" src="@/assets/images/zhongxinzd.png" alt="" @click="goToPage('createOrder')">
        <img class="btn-arrow" src="../../assets/images/ddjiantou.png" alt="">
      </div>
      <div class="btn-item">
        <img class="btn-pic" src="@/assets/images/qunzhongdd.png" alt="" @click="goToPage('pointOrder')">
        <img class="btn-arrow" src="../../assets/images/ddjiantou.png" alt="">
      </div>
      <div class="btn-item">
        <img class="btn-pic" src="@/assets/images/zhiyuanjd.png" alt="" @click="goToPage('handOrder')">
        <img class="btn-arrow" src="../../assets/images/ddjiantou.png" alt="">
      </div>
      <div class="btn-item">
        <img class="btn-pic" src="@/assets/images/shehuipd.png" alt="" @click="goToPage('talkOrder')">
      </div>
    </div>
  </div>
</template>

<script>
import peopleOrderApi from "@/api/peopleOrder";
import Tree from '../massorder/tree';
export default {
  components: {
    Tree
  },
  data() {
    return {
      serveList: [],
      show: false
    }
  },
  mounted() {
    this.getservertree()
  },
  methods: {
    /**
    * 开门询单: 跳转微心愿页面 @sign="askOrder"
    * 中心制单：跳转服务菜单页面 @sign="createOrder"
    * 群众点单：跳转群众点单页面 @sign="pointOrder"
    * 志愿接单：跳转活动页面 @sign="handOrder"
    * 群众评单：跳转活动页面 @sign="talkOrder"
    */
    goToPage(sign) {
      if (sign === 'askOrder') {
        this.$router.push({ path: '/demand', query: { backSign: 'askOrder' } })
      } else if (sign === "createOrder") {
        this.$router.push('/centerOrder')
      } else if (sign === "pointOrder") {
        this.$router.push({ path: '/massorderTwo', query: { backSign: 'pointOrder' } })
      } else if (sign === "handOrder" || sign === "talkOrder") {
        this.$router.push({ path: '/punchtheclockFive', query: { backSign: 'handOrder' } })
      }
    },

    //获取服务类型
    getservertree() {
      let params = {
        appName: this.appName
      }
      peopleOrderApi.servertree(params).then(res => {
        if (res.success) {
          this.serveList = res.data
        }
      })
    },

    closeTree() {
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
.five-order-main {
  .btn-item {
    text-align: center;
    .btn-pic {
      margin: 0.25rem auto;
      display: block;
      width: 62%;      
    }
    .btn-arrow {
      width: 10%;
    }
  }
}
.tree-head {
  .first-back {
    position: fixed;
    top: 10px;
    left: 13px;
  }
}
</style>